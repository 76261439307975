import React, { useState } from "react";

import '../styles.css';

const Home = ( props ) => {
    const { theme, setTheme } = props;

    const [tab1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);
    const [tab4, setTab4] = useState(false);
    const [tab5, setTab5] = useState(false);
    const [tab6, setTab6] = useState(false);
    const [tab7, setTab7] = useState(false);
    const [tab8, setTab8] = useState(false);

    return (
        <>
        <div className="hero">
            <div className="title">Hi</div>
            <div className="subtitle">I'm Braden, a video creator / software developer based in San Francisco.</div>

            <div className="picker-box">
                <button type="button" className={`picker ${(theme === "default" || theme === "light" || !theme) && 'border'}`} style={{ background: "#fff5f5" }} onClick={() => {setTheme("default"); localStorage.setItem('theme', "default")}}>{(theme === "default" || theme === "light" || !theme) && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "1" && 'border'}`} style={{ background: "#EFD1CF" }} onClick={() => {setTheme("1"); localStorage.setItem('theme', "1")}}>{theme === "1" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "9" && 'border'}`} style={{ background: "#F93822FF" }} onClick={() => {setTheme("9"); localStorage.setItem('theme', "9")}}>{theme === "9" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "3" && 'border'}`} style={{ background: "#ffd700" }} onClick={() => {setTheme("3"); localStorage.setItem('theme', "3")}}>{theme === "3" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "7" && 'border'}`} style={{ background: "#008c15" }} onClick={() => {setTheme("7"); localStorage.setItem('theme', "7")}}>{theme === "7" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "10" && 'border'}`} style={{ background: "#D6ED17FF" }} onClick={() => {setTheme("10"); localStorage.setItem('theme', "10")}}>{theme === "10" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "4" && 'border'}`} style={{ background: "#ADEFD1FF" }} onClick={() => {setTheme("4"); localStorage.setItem('theme', "4")}}>{theme === "4" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "2" && 'border'}`} style={{ background: "#36399a" }} onClick={() => {setTheme("2"); localStorage.setItem('theme', "2")}}>{theme === "2" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "5" && 'border'}`} style={{ background: "#422057FF" }} onClick={() => {setTheme("5"); localStorage.setItem('theme', "5")}}>{theme === "5" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
                <button type="button" className={`picker ${theme === "8" && 'border'}`} style={{ background: "#1C1C1BFF" }} onClick={() => {setTheme("8"); localStorage.setItem('theme', "8")}}>{theme === "8" && <i className="material-symbols-outlined inline-icon" style={{ color: "var(--text)" }}>check</i>}</button>
            </div>

            <div className="label">PORTFOLIO</div>
        </div>

        <div className="portfolio">
            <div className="item" onClick={() => setTab1(tab1 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab1 === true ? 'remove' : 'add'}</i>Beluga
                <span className="field">Social Media</span>
            </div>
            {tab1 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>Beluga is a YouTube channel focused on Discord skits. Each video follows a similar format, where a relatable topic is turned into an engaging text conversation.<br/><br/>In June 2021, the channel gained immense popularity and was recognized by YouTube as the #4 breakout creator of the year. Since then, it has accumulated over 10 million subscribers and 2.2 billion views. The Beluga Discord server has also grown, and currently has over 780,000 members.<br/><br/>From this experience, I learned how to manage large communities, improve my storytelling skills, and maintain a positive brand image.</div>
                    </div>

                    <div className="flex-container">
                        <div className="video_container">
                            <iframe className="video" width="560" height="315" src="https://www.youtube.com/embed/69Hitt9Dw4Y?si=YlyJ5HAeBn472IO4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <img className="image" style={{ width: "100px" }} src={require('../images/beluga.jpg')} alt=""/>
                    </div>
                    
                    <div style={{ marginTop: "30px", marginBottom: "10px" }}><a href="https://www.youtube.com/@Beluga1" target="_blank" rel="noreferrer" className="button">View channel&nbsp;&nbsp;<i className="material-symbols-outlined" style={{ color: "var(--text)", marginBottom: "6px", verticalAlign: "bottom" }}>north_east</i></a></div>
                </div>
            )}

            <div className="item" onClick={() => setTab6(tab6 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab6 === true ? 'remove' : 'add'}</i>Explore Lucid Dreaming
                <span className="field">Social Media</span>
            </div>
            {tab6 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>Explore Lucid Dreaming is an educational YouTube channel covering the topic of lucid dreaming. Started in 2019, the channel posted a series of videos on how to improve the skill of controlling one's dreams.<br/><br/>Through this experience, I learned how to take complex concepts and explain them to a younger audience in a clear way. The videos were designed to keep the viewer engaged, entertained, and motivated to practice the techniques.<br/><br/>Explore Lucid Dreaming currently has 1.2 million subscribers, and is the largest channel in the lucid dreaming space.</div>
                    </div>
                    
                    <div className="flex-container">
                        <div className="video_container">
                            <iframe className="video" width="560" height="315" src="https://www.youtube.com/embed/rvCccjvb6lM?si=KPyEmLuSrmmtiTt9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <img className="image" style={{ width: "100px" }} src={require('../images/eld.jpg')} alt=""/>
                    </div>
                        
                    <div style={{ marginTop: "30px", marginBottom: "10px" }}><a href="https://www.youtube.com/@ExploreLucidDreaming1" target="_blank" rel="noreferrer" className="button">View channel&nbsp;&nbsp;<i className="material-symbols-outlined" style={{ color: "var(--text)", marginBottom: "6px", verticalAlign: "bottom" }}>north_east</i></a></div>
                </div>
            )}

            <div className="item" onClick={() => setTab8(tab8 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab8 === true ? 'remove' : 'add'}</i>808
                <span className="field">Software</span>
            </div>
            {tab8 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>808 is a social app based on a simple idea:<br/><br/>Every evening at 8:08 PM, the app unlocks. Everyone in your city has 30 seconds to take a photo. Then, you can view all the photos taken by people around you. If you like a photo, you can chat with whoever posted it. People can also message you if they liked your picture.</div>
                    </div>
                    
                    <div className="flex-container">
                        <img className="image" style={{ width: "100px" }} src={require('../images/lightspeed.png')} alt=""/>
                    </div>

                    <div style={{ marginTop: "30px", marginBottom: "10px" }}><a href="https://808.social" target="_blank" rel="noreferrer" className="button">Download for iOS&nbsp;&nbsp;<i className="material-symbols-outlined" style={{ color: "var(--text)", marginBottom: "6px", verticalAlign: "bottom" }}>north_east</i></a></div>
                </div>
            )}

            <div className="item" onClick={() => setTab2(tab2 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab2 === true ? 'remove' : 'add'}</i>Parrot
                <span className="field">Software</span>
            </div>
            {tab2 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>Parrot is a no-code platform for student entrepreneurs at the University of Arizona to quickly prototype apps. Simply enter the app name, choose a template, and customize the layout in the drag and drop builder.<br/><br/>Parrot uses a special algorithm to convert the frontend design to React Native code. This allows users to easily test their apps on device. Through this venture, I gained experience meeting customer needs, putting together pitch decks, and working with a team to present in front of an audience.<br/><br/>In Spring of 2024, our team won 3rd place in the New Venture Competition.</div>
                    </div>
                        
                    <div className="flex-container">
                        <div className="video_container">
                            <iframe className="video" width="560" height="315" src="https://www.youtube.com/embed/Auk20L6hxi4?si=XPfRYoaQ56mRNqsR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                        <img className="image fullimage" src={require('../images/parrot2.jpg')} alt=""/>
                    </div>
                    
                    <div style={{ marginTop: "30px", marginBottom: "10px" }}><a href="https://eller.arizona.edu/news/student-innovators-showcase-their-inventions-2024-new-venture-competition" target="_blank" rel="noreferrer" className="button">Read more&nbsp;&nbsp;<i className="material-symbols-outlined" style={{ color: "var(--text)", marginBottom: "6px", verticalAlign: "bottom" }}>north_east</i></a></div>
                </div>
            )}

            <div className="item" onClick={() => setTab7(tab7 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab7 === true ? 'remove' : 'add'}</i>AI Core
                <span className="field">Software</span>
            </div>
            {tab7 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>AI Core is an emerging program at the University of Arizona centered around artificial intelligence. I was responsible for building the main website, as well as the chatbot interface offered to students.<br/><br/>From working at AI Core, I gained experience working with a medium-sized developer team, fine-tuning language models, and connecting them to a user-friendly frontend.</div>
                    </div>

                    <div className="flex-container">
                        <img className="image fullimage" style={{ marginRight: "20px" }} src={require('../images/aicore1.png')} alt=""/>
                        <img className="image fullimage" src={require('../images/aicore2.png')} alt=""/>
                    </div>
                    
                    <div style={{ marginTop: "30px", marginBottom: "10px" }}><a href="https://aicore.arizona.edu/chat" target="_blank" rel="noreferrer" className="button">Use chatbot&nbsp;&nbsp;<i className="material-symbols-outlined" style={{ color: "var(--text)", marginBottom: "6px", verticalAlign: "bottom" }}>north_east</i></a></div>
                </div>
            )}

            <div className="item" onClick={() => setTab5(tab5 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab5 === true ? 'remove' : 'add'}</i>Anamu AI
                <span className="field">Software</span>
            </div>
            {tab5 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>Anamu is an AI-powered social media management tool that aims to help people improve their personal brand. Select from a list of recommended platforms, create a posting schedule, and GPT-4 will generate content based on personal data you provide.<br/><br/>This project taught me more about UX/UI design, integrating AI into a consumer-facing product, and using prompt engineering to generate content relevant to each unique person.</div>
                    </div>
                        
                    <div className="flex-container">
                        <img className="image fullimage" style={{ marginRight: "20px" }} src={require('../images/anamu1.png')} alt=""/>
                        <img className="image fullimage" src={require('../images/anamu2.png')} alt=""/>
                    </div>
                </div>
            )}

            <div className="item" onClick={() => setTab4(tab4 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab4 === true ? 'remove' : 'add'}</i>Harvest
                <span className="field">Software</span>
            </div>
            {tab4 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>Harvest is a mobile app which uses AI to generate recipe ideas, based on the ingredients you have in your fridge. You can add filters and special requests, which the AI will consider before recommending options.<br/><br/>Harvest was built in React Native and uses GPT-4 and Google's Search API to pull the right data. This project helped me gain experience in mobile development, prompt engineering, and retrieving data based on AI responses.</div>
                    </div>

                    <div className="flex-container">
                        <img className="image tinyimage" src={require('../images/harvest2.png')} alt=""/>
                        <img className="image tinyimage" src={require('../images/harvest1.png')} alt=""/>
                        <img className="image tinyimage" src={require('../images/harvest3.png')} alt=""/>
                    </div>
                </div>
            )}

            <div className="item" onClick={() => setTab3(tab3 === false ? true : false)}>
                <i className="material-symbols-outlined inline-icon" style={{ marginRight: "10px", marginBottom: "2px" }}>{tab3 === true ? 'remove' : 'add'}</i>Mindwire
                <span className="field">Software</span>
            </div>
            {tab3 === true && (
                <div className="description">
                    <div className="description_text">
                        <div>Mindwire is an AI-powered motivational app, which tracks goals and provides advice via a chatbot. You can create a personalized coach and have it text you throughout the day, keeping you motivated and accountable to your goals.<br/><br/>From building this app, I gained experience in mobile development, UX/UI design, and using prompt engineering to create unique personalities for users to interact with.</div>
                    </div>

                    <div className="flex-container">
                        <img className="image tinyimage" src={require('../images/mindwire1.png')} alt=""/>
                        <img className="image tinyimage" src={require('../images/mindwire2.png')} alt=""/>
                        <img className="image tinyimage" src={require('../images/mindwire3.png')} alt=""/>
                    </div>
                </div>
            )}
        </div>

        <div className="footer">
            <div className="subtitle">If you'd like to connect, <a href="https://www.linkedin.com/in/bradenboyko/" target="_blank" rel="noreferrer">click here</a>.</div>
        </div>
        </>
    );
}

export default Home;