import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Home from "./components/Home";

const App = () => {

  const savedTheme = localStorage.getItem('theme') || 'default';
  const [theme, setTheme] = useState(savedTheme);

  useEffect(() => {
    const themeColorMeta = document.getElementById('theme-color-meta');

    if (theme === "0") {
      document.documentElement.style.background = "#EEA47FFF";
      themeColorMeta?.setAttribute('content', '#EEA47FFF');
      document.documentElement.style.setProperty('--background', '#EEA47FFF');
      document.documentElement.style.setProperty('--text', '#00539CFF');

    } else if (theme === "1") {
      document.documentElement.style.background = "#EFD1CF";
      themeColorMeta?.setAttribute('content', '#EFD1CF');
      document.documentElement.style.setProperty('--background', '#EFD1CF');
      document.documentElement.style.setProperty('--text', '#912637');

    } else if (theme === "2") {
      document.documentElement.style.background = "#36399a";
      themeColorMeta?.setAttribute('content', '#36399a');
      document.documentElement.style.setProperty('--background', '#36399a');
      document.documentElement.style.setProperty('--text', '#ef1897');

    } else if (theme === "3") {
      document.documentElement.style.background = "#ffd700";
      themeColorMeta?.setAttribute('content', '#ffd700');
      document.documentElement.style.setProperty('--background', '#ffd700');
      document.documentElement.style.setProperty('--text', '#db1020');

    } else if (theme === "4") {
      document.documentElement.style.background = "#ADEFD1FF";
      themeColorMeta?.setAttribute('content', '#ADEFD1FF');
      document.documentElement.style.setProperty('--background', '#ADEFD1FF');
      document.documentElement.style.setProperty('--text', '#00203FFF');

    } else if (theme === "5") {
      document.documentElement.style.background = "#422057FF";
      themeColorMeta?.setAttribute('content', '#422057FF');
      document.documentElement.style.setProperty('--background', '#422057FF');
      document.documentElement.style.setProperty('--text', '#FCF951FF');

    } else if (theme === "7") {
      document.documentElement.style.background = "#008c15";
      themeColorMeta?.setAttribute('content', '#008c15');
      document.documentElement.style.setProperty('--background', '#008c15');
      document.documentElement.style.setProperty('--text', '#ffc600');

    } else if (theme === "8") {
      document.documentElement.style.background = "#1C1C1BFF";
      themeColorMeta?.setAttribute('content', '#1C1C1BFF');
      document.documentElement.style.setProperty('--background', '#1C1C1BFF');
      document.documentElement.style.setProperty('--text', '#CE4A7EFF');

    } else if (theme === "9") {
      document.documentElement.style.background = "#F93822FF";
      themeColorMeta?.setAttribute('content', '#F93822FF');
      document.documentElement.style.setProperty('--background', '#F93822FF');
      document.documentElement.style.setProperty('--text', '#FDD20EFF');

    } else if (theme === "10") {
      document.documentElement.style.background = "#D6ED17FF";
      themeColorMeta?.setAttribute('content', '#D6ED17FF');
      document.documentElement.style.setProperty('--background', '#D6ED17FF');
      document.documentElement.style.setProperty('--text', '#606060FF');

    } else if (theme === "11") {
      document.documentElement.style.background = "#CBCE91FF";
      themeColorMeta?.setAttribute('content', '#CBCE91FF');
      document.documentElement.style.setProperty('--background', '#CBCE91FF');
      document.documentElement.style.setProperty('--text', '#76528BFF');

    } else {
      document.documentElement.style.background = "#fff5f5";
      themeColorMeta?.setAttribute('content', '#fff5f5');
      document.documentElement.style.setProperty('--background', '#fff5f5');
      document.documentElement.style.setProperty('--text', '#2c2c2c');
    }
  }, [theme]);

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home theme={theme} setTheme={setTheme}/>}/>
        <Route path="*" exact element={<Navigate to="/"/>}/>
      </Routes>
    
    </BrowserRouter>
    </>
  );
}

export default App;